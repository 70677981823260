import React from 'react';
import SprintIcon from '@atlaskit/icon/core/sprint';
import SprintField from '@atlassian/jira-issue-create-commons/src/ui/sprint-field';
import type { SelectOption } from '@atlassian/jira-issue-field-select/src/ui/edit/types';
import { SELECT_CF_TYPE } from '@atlassian/jira-platform-field-config';
import { DropdownFieldPlaceholder } from '../../../ui/field-placeholders';
import type { FieldTypeConfig, ProvidedFieldProps } from '../types';
import { selectFieldWriter } from '../writers';

const SprintFieldTypeComponent = (props: ProvidedFieldProps) => {
	const { projectKey, canUserViewProject } = props;

	if (!projectKey || !canUserViewProject) {
		return null;
	}

	// This is the same URL as the default for `SprintField` - the only difference here is we
	// want to hardcode the options list to only show sprints from parent project instead of showing
	// sprints across all projects visible to the user
	const autoCompleteUrl = `/rest/greenhopper/1.0/sprint/picker?projectKey=${projectKey}&query=`;

	return <SprintField {...props} autoCompleteUrl={autoCompleteUrl} />;
};

export const SprintFieldTypeConfig: FieldTypeConfig<SelectOption> = {
	icon: SprintIcon,
	type: SELECT_CF_TYPE,
	Component: SprintFieldTypeComponent,
	placeholder: <DropdownFieldPlaceholder />,
	writer: selectFieldWriter,
};
