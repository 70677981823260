import React from 'react';
import LinkIcon from '@atlaskit/icon/core/link';
import UrlField from '@atlassian/jira-issue-create-commons/src/ui/url-field/index.tsx';
import { URL_CF_TYPE } from '@atlassian/jira-platform-field-config';
import { TextFieldPlaceholder } from '../../../ui/field-placeholders';
import type { FieldTypeConfig } from '../types';
import { urlFieldWriter } from '../writers';

export const UrlCustomFieldTypeConfig: FieldTypeConfig<string> = {
	icon: LinkIcon,
	type: URL_CF_TYPE,
	Component: UrlField,
	placeholder: <TextFieldPlaceholder />,
	writer: urlFieldWriter,
};
