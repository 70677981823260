import React from 'react';
import PriorityHighestIcon from '@atlaskit/icon/core/priority-highest';
import PriorityField from '@atlassian/jira-issue-create-commons/src/ui/priority-field/index.tsx';
import type { Option } from '@atlassian/jira-issue-field-priority/src/ui/edit/types.tsx';
import { PRIORITY_TYPE } from '@atlassian/jira-platform-field-config';
import { DropdownFieldPlaceholder } from '../../../ui/field-placeholders';
import type { FieldTypeConfig, ProvidedFieldProps } from '../types';
import { priorityFieldWriter } from '../writers';

const PriorityFieldTypeComponent = (props: ProvidedFieldProps) => <PriorityField {...props} />;

export const PriorityFieldTypeConfig: FieldTypeConfig<Option> = {
	icon: PriorityHighestIcon,
	type: PRIORITY_TYPE,
	Component: PriorityFieldTypeComponent,
	placeholder: <DropdownFieldPlaceholder />,
	writer: priorityFieldWriter,
};
