import React from 'react';
import ClockIcon from '@atlaskit/icon/core/clock';
import DateField from '@atlassian/jira-issue-create-commons/src/ui/date-field/index.tsx';
import { DATE_CF_TYPE } from '@atlassian/jira-platform-field-config';
import { DateFieldPlaceholder } from '../../../ui/field-placeholders';
import type { FieldTypeConfig, ProvidedFieldProps } from '../types';
import { dateFieldWriter } from '../writers';

const DateCustomFieldTypeComponent = (props: ProvidedFieldProps) => <DateField {...props} />;

export const DateCustomFieldTypeConfig: FieldTypeConfig<string> = {
	icon: ClockIcon,
	type: DATE_CF_TYPE,
	Component: DateCustomFieldTypeComponent,
	placeholder: <DateFieldPlaceholder />,
	writer: dateFieldWriter,
};
