import React from 'react';
import TagIcon from '@atlaskit/icon/core/tag';
import LabelsField from '@atlassian/jira-issue-create-commons/src/ui/labels-field/index.tsx';
import type { LabelOption as Option } from '@atlassian/jira-issue-shared-types/src/common/types/labels-type.tsx';
import { LABELS_CF_TYPE } from '@atlassian/jira-platform-field-config';
import { DropdownFieldPlaceholder } from '../../../ui/field-placeholders';
import type { FieldTypeConfig, ProvidedFieldProps } from '../types';
import { labelsFieldWriter } from '../writers';

const LabelsCustomFieldTypeComponent = (props: ProvidedFieldProps) => <LabelsField {...props} />;

export const LabelsCustomFieldTypeConfig: FieldTypeConfig<Option[]> = {
	icon: TagIcon,
	type: LABELS_CF_TYPE,
	Component: LabelsCustomFieldTypeComponent,
	placeholder: <DropdownFieldPlaceholder />,
	writer: labelsFieldWriter,
};
