import React from 'react';
import ComponentIcon from '@atlaskit/icon/core/component';
import ComponentsField from '@atlassian/jira-issue-create-commons/src/ui/components-field/index.tsx';
import type { Options } from '@atlassian/jira-issue-field-components-field/src/ui/edit/types.tsx';
import type { SelectOption } from '@atlassian/jira-issue-field-select/src/ui/edit/types.tsx';
import { COMPONENTS_TYPE } from '@atlassian/jira-platform-field-config';
import { DropdownFieldPlaceholder } from '../../../ui/field-placeholders';
import type { FieldTypeConfig, FieldWriter, ProvidedFieldProps } from '../types';
import { multiSelectFieldWriter } from '../writers';

const ComponentsFieldTypeComponent = (props: ProvidedFieldProps) => <ComponentsField {...props} />;

// Effectively the same as `multiSelectFieldWriter`. For some reason, the type of the field value of
// `ComponentsField` (`Options`) has a nullable value type. This function just pre-processes the value
// to strip away any `null` value options before passing it to `multiSelectFieldWriter`.
const componentsFieldWriter: FieldWriter<Options> = (id, value, fields) => {
	const filteredValues = value.filter<SelectOption>(
		(option): option is SelectOption => option.value !== null,
	);
	multiSelectFieldWriter(id, filteredValues, fields);
};

export const ComponentsFieldTypeConfig: FieldTypeConfig<Options> = {
	icon: ComponentIcon,
	type: COMPONENTS_TYPE,
	Component: ComponentsFieldTypeComponent,
	placeholder: <DropdownFieldPlaceholder />,
	writer: componentsFieldWriter,
};
