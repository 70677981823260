import React from 'react';
import PeopleGroupIcon from '@atlaskit/icon/core/people-group';
import type { UserOption } from '@atlassian/jira-base-user-picker/src/types.tsx';
import MultiUserPickerField from '@atlassian/jira-issue-create-commons/src/ui/multi-userpicker-field/index.tsx';
import { MULTI_USER_CF_TYPE } from '@atlassian/jira-platform-field-config';
import { AssigneeFieldPlaceholder } from '../../../ui/field-placeholders';
import type { FieldTypeConfig } from '../types';
import { multiUserPickerFieldWriter } from '../writers';

export const MultiUserCustomFieldTypeConfig: FieldTypeConfig<UserOption[]> = {
	icon: PeopleGroupIcon,
	type: MULTI_USER_CF_TYPE,
	Component: MultiUserPickerField,
	placeholder: <AssigneeFieldPlaceholder />,
	writer: multiUserPickerFieldWriter,
};
