import React from 'react';
import TaskIcon from '@atlaskit/icon/core/task';
import CheckboxSelectField from '@atlassian/jira-issue-create-commons/src/ui/checkbox-select-field/index.tsx';
import type { Options } from '@atlassian/jira-issue-field-checkbox-select/src/ui/edit/types.tsx';
import { MULTI_CHECKBOXES_CF_TYPE } from '@atlassian/jira-platform-field-config';
import { DropdownFieldPlaceholder } from '../../../ui/field-placeholders';
import type { FieldTypeConfig, ProvidedFieldProps } from '../types';
import { checkboxSelectFieldWriter } from '../writers';

const MultiCheckboxesCustomFieldTypeComponent = (props: ProvidedFieldProps) => (
	<CheckboxSelectField {...props} />
);

export const MultiCheckboxesCustomFieldTypeConfig: FieldTypeConfig<Options> = {
	icon: TaskIcon,
	type: MULTI_CHECKBOXES_CF_TYPE,
	Component: MultiCheckboxesCustomFieldTypeComponent,
	placeholder: <DropdownFieldPlaceholder />,
	writer: checkboxSelectFieldWriter,
};
